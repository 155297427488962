<template>
    <div v-if="loading" class="flex justify-center mt-32">
        <svg class="animate-spin ml-3 h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    </div>

    <div class="m-2" v-else>
        <div class="content-container checkout-box radius-anchor">
            <div v-if="orderjobs.length > 0">
                <div class="text-center">
                    <h2 class="text-3xl font-bold">{{ $t('quickpickup.orderjobs.title') }}</h2>
                    <h3 class="text-xl font-bold">{{ $t('quickpickup.orderjobs.subtitle') }}</h3>
                </div>

                <div v-for="(orderjob, index) in orderjobs" v-bind:key="index" class="mt-1 border rounded-sm p-4 mb-5 bg-gray-50 text-center">
                    <div class="mt-1 border rounded-sm p-4 mb-5 bg-gray-50 text-center">
                        <div class="font-bold">{{ orderjob.attributes.location_label }}</div>
                        <modal-quickpickup-orderjob :location_slug="location_slug" :orderjob="orderjob" @success="orderjob.attributes.status = 'pickedup'" @failed="getOrderjobs()" />
                    </div>

                    <div v-for="(line, line_index) in orderjob.attributes.lines" v-bind:key="line_index" class="flex justify-start">
                        <div class="mr-3">
                            {{ Math.round(line.quantity) }}
                        </div>
                        <div>{{ line.description }}</div>
                    </div>
                </div>

            </div>

            <!-- no orderjobs -->
            <div v-else>
                <div class="text-center pt-5">
                    <h2 class="text-2xl font-bold mb-3">{{ $t('quickpickup.no-orderjobs.title') }}</h2>
                    <h3 class="text-lg">{{ $t('quickpickup.no-orderjobs.subtitle') }}</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import * as _api from '../../lib/api'

// modals
import ModalQuickpickupOrderjob from '../../components/ModalQuickpickupOrderjob.vue'

export default {
   components: {
       ModalQuickpickupOrderjob
   },
    name: 'Quickpickup',
    props: ['location_slug', 'transaction_id'],
    data() {
        return {
            loading: true,
            orderjobs: [],
        }
    },
    mounted() {
        axios.defaults.headers.common['Location'] = this.location_slug

        this.getOrderjobs();
    },
    methods: {
        async getOrderjobs() {
            // todo loading spinner && needs testing
            this.orderjobs = await _api.get('transaction/:transaction_id/quickpickup', this.transaction_id);
            this.loading = false;
            // axios.get('/transaction/' + this.transaction_id + '/quickpickup').then(response => {
            //     this.orderjobs = response.data.data
            // }).catch(() => {
            //     console.log('error')
            // }).finally(() => {
            //     this.loading = false
            // })
        },
    },
}
</script>
