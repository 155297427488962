<template>
    <a v-if="orderjob.attributes.status != 'pickedup'" @click="open()" class="mr-2 cursor-pointer inline-flex justify-center items-center  px-3 py-2 btn btn-primary text-sm leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
        {{ $t('modal.quickpickup.orderjob.button.show')}}
    </a>
    <div v-else>
        {{ $t('modal.quickpickup.orderjob.pickedup') }}
    </div>

    <!-- modal -->
    <div class="fixed w-full z-40 inset-0 ccss--modal-quickpickup-orderjob" v-if="show">
        <div class="flex items-end justify-center h-full w-full text-center sm:p-0">
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle"></span>&#8203;
            <div class="h-full w-full inline-block align-bottom bg-gray-900 text-white rounded-sm text-left shadow-xl transform transition-all" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

                    <div class="flex justify-between">
                        <h2 class="text-gray-500 text-center text-3xl font-extrabold">
                            {{ $t('modal.quickpickup.orderjob.title') }}
                        </h2>
                        <a @click="close()" class="cursor-pointer text-gray-200 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                            <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </a>
                    </div>

                    <!-- transaction -->
                    <div class="h-60vh overflow-auto">
                        <div v-for="(line, line_index) in orderjob.attributes.lines" v-bind:key="line_index" class="flex justify-start text-lg">
                            <div class="w-10">
                                {{ Math.round(line.quantity) }}
                            </div>
                            <div>{{ line.description }}</div>
                        </div>
                    </div>

                    <div class="h-20vh text-center p-3 border-t">
                        <!-- <a @click="process()" class="w-full cursor-pointer inline-flex justify-center items-center  px-3 py-2 btn btn-primary text-sm leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                            {{ $t('modal.quickpickup.orderjob.button.process')}}
                        </a> -->
                        <swipe-button
                            ref="swipeButton"
                            class="swipe-button"
                            @actionConfirmed="process"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="status == 'success'" class="fixed h-screen w-full z-50 opacity-50 inset-0 bg-green-500 fade-enter-active"></div>
        <div v-if="status == 'failed'" class="fixed h-screen w-full z-50 opacity-50 inset-0 bg-red-500 fade-enter-active"></div>
    </div>
</template>

<script>
import axios from 'axios'
import * as _api from '../lib/api'
import SwipeButton from './SwipeButton.vue'

export default {
    name: 'ModalQuickpickupOrderjob',
    props: ['location_slug', 'orderjob'],
    components: {
        SwipeButton,
    },
    data() {
        return {
            show: false,
            status: null,
        }
    },
    mounted() {
        axios.defaults.headers.common['Location'] = this.location_slug
    },
    methods: {

        // modal
        open() {
            this.reset()
            // this.status = 'success'
            this.show = true
        },

        close() {
            this.reset()
            this.show = false
        },
        reset() {
            this.status = null
        },

		async process() {

            this.saving = true

            // todo loading spinner
            // todo: needs testing
            const transaction_data = await _api.post(
                'transaction/:transaction_id/quickpickup/process',
                {orderjob_id: this.orderjob.id},
                this.orderjob.attributes.transaction_id
            );

            if (transaction_data) {
                this.$emit('success')
                this.status = 'success'
            } else {
                this.$emit('failed')
                this.status = 'failed'
            }

            setTimeout(() => {
                this.close()
            }, 1000)

            this.saving = false

		},
    },
}
</script>
